import $ from 'jquery';

export default (create_customer_request_object, callback) => {
    var login_gql = `mutation Login($email: String!, $pass: String!) {
        login(email: $email, password: $pass) {
          result
        }
      }`;
    var login_variables = {
        "email": create_customer_request_object['register-email'],
        "pass": create_customer_request_object['register-password'],
    };

    $.ajax({
        "url": '/graphql',
        "method": "POST",
        "type": "POST",
        "timeout": 20000,
        "credentials": "same-origin",
        "headers": {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + window.storefront_api_token,
        },
        "data": JSON.stringify({
            query: login_gql,
            variables: login_variables
        }),
        dataType: "json",
        processData: false,
    }).done(function (login_customer_response) {
        //console.log('login_customer_response', login_customer_response);
        if(login_customer_response){
            if(login_customer_response.errors){
                callback({
                    success: false,
                    error_message: 'Error logging into your account'
                });
            } else {
                callback({
                    success: true
                })
            }
        }
    }).fail(function (response) {
        //console.log('response', response);
        if (response.responseJSON.message) {
            callback({
                success: false,
                error_message: 'Error creating account: ' + response.responseJSON.message,
            });
        } else {
            callback({
                success: false,
                error_message: 'Error creating account, please review your information and try again.',
            });
        }
    });
}
