export function setLocalStorageWithExpiry(key, value, expiry_in_hours) {

    let expiry_in_ms = expiry_in_hours * 60 * 60 * 1000;

    localStorage.setItem(key, JSON.stringify({
        value: value,
        expiry: (new Date()).getTime() + expiry_in_ms
    }));
}

export function getLocalStorageWithExpiry(key, default_value) {
    let local_storage_data = localStorage.getItem(key);

    try {
        let parsed_local_storage_data = JSON.parse(local_storage_data);
        if (parsed_local_storage_data != null) {
            let current_timestamp_in_ms = (new Date()).getTime();
            if (current_timestamp_in_ms > parsed_local_storage_data.expiry) {
                localStorage.removeItem(key);

                return default_value;
            } else {

                return parsed_local_storage_data.value;
            }
        } else {

            return default_value;
        }
    }
    catch (e) {
        console.error(e);
        localStorage.removeItem(key);
    }
}