import InfoModal from './ApplicationModal';

export default class RegisterCustomerSuccessModal extends InfoModal {
  constructor(target, content) {
    super(target, content);
  }
  handleAction(target) {
    switch ($(target).data('action')) {
      case 'dismiss':
        this.completePath();
        break;
      case 'view-cart':
        window.location.href = '/cart.php';
        break;
      default:
        console.error('no action implemented for: ', target);
    }
  }
}
