import $ from 'jquery';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import compareProducts from './global/compare-products';
import privacyCookieNotification from './global/cookieNotification';
import maintenanceMode from './global/maintenanceMode';
import carousel from './common/carousel';
import 'lazysizes';
import sweetAlert from './global/sweet-alert';
import svgInjector from './global/svg-injector';

/*=========================================
=            www.themevale.com            =
=========================================*/

import themevale_Global from './themevale/themevale_Global';
import themevale_AddToCart from './themevale/themevale_AddToCart';
import themevale_RecentlyBought from './themevale/themevale_RecentlyBought';
import themevale_Sticky from './themevale/themevale_StickyNavigation';
    window.themevale_Sticky = themevale_Sticky;
import themevaleMenu from './themevale/themevale_MegaMenu';
    window.themevaleMenu = themevaleMenu;
import themevaleNewsletterPopup from './themevale/themevale_NewsletterPopup';
    window.themevaleNewsletterPopup = themevaleNewsletterPopup;
/*=====  End of www.themevale.com  ======*/

export default class Global extends PageManager {
    onReady() {
        // Only load visible elements until the onload event fires,
        // after which preload nearby elements.
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.loadMode = 1;

        cartPreview(this.context);

        quickSearch();
        currencySelector();
        foundation($(document));
        quickView(this.context);
        compareProducts(this.context.urls);
        carousel();
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        maintenanceMode(this.context.maintenanceMode);
        sweetAlert();
        svgInjector();

        themevale_Global();
        themevale_AddToCart();
        themevale_RecentlyBought(this.context);

        // --------------- Re-adjust Widget Positions -------------------------
        // elements are also re-adjusted in assets\scss\custom.scss
        //Run setWidgetPosition 2 seconds after page load
        setTimeout(setWidgetPosition, 2000);

        //Run setWidgetPosition on window resize
        $(window).on('resize', setWidgetPosition);

        //Run setWidgetPosition on button clicks in the Data Collection Preferences consent manager
        $('#consent-manager').find('button').eq(0).on('click', function() {
            let $consentModalSaveBtn = $('[data-consent-manager-dialog]').find('button').eq(2);
            $consentModalSaveBtn.on('click', function() {
                setTimeout(setWidgetPosition, 2000);
            });
        });
        $('#consent-manager').find('button').eq(1).on('click', setWidgetPosition);
        $('#consent-manager').find('button').eq(2).on('click', setWidgetPosition);

        // ---------------- ADA Compliance - MSUS-1011 ---------------------
            // Add alt text to flag currency images
            let $currenciesContainer = $(".themevale_MobileCurrency");
            if ($currenciesContainer.find(".item").length > 0) {
                $currenciesContainer.find(".item").each(function() {
                    let currencyText = $(this).find("strong").text();
                    $(this).find("img").attr("alt", currencyText);
                });
            }

            //Add aria-label to iframes - Freshdesk 354937
                // iChat
                let $chatDivWrap = $('#chat-div-wrap');
                let $buttonIframeWrap = $('.button-iframe-wrap');
                if ($chatDivWrap.has('#icChat')) {
                    $chatDivWrap.find('#icChat').attr('aria-label', 'Live Chat window');
                }
                if ($buttonIframeWrap.has('#icChatButton')) {
                    $buttonIframeWrap.find('#icChatButton').attr('aria-label', 'Button for Live Chat');
                }

                // Product view description
                let $productViewDesc = $('.productView-description');
                $productViewDesc.find('iframe').each(function() {
                    $(this).attr('aria-label', 'Product description media');
                });

                // Page content
                let $pageContent = $('.page-content');
                $pageContent.find('iframe').each(function() {
                    $(this).attr('aria-label', 'Page content media');
                });
            // .End Add aria-label
        // ---------------- .End ADA Compliance ------------------

        //Reset the position of the widgets based on the size of the Data Collection Preferences consent manager
        let run_setWidgetPosition_once = false;
        function setWidgetPosition() {
            // if the ConveyThis widget hasn't loaded make sure to wait 4 seconds to re-run this function
            if(typeof(window.conveythis) !== 'object' && !run_setWidgetPosition_once) {
                setTimeout(setWidgetPosition, 4000);
                run_setWidgetPosition_once = true;
                return;
            }
            let consentManagerHeight = Math.ceil($('#consent-manager').height());
            if(consentManagerHeight === 0) {
                let consentManagerUpdateBannerHeight = $('#consent-manager-update-banner').length ? Math.ceil($('#consent-manager-update-banner').height()) : 48;
                $('#conveythis-wrapper').css({'bottom': consentManagerUpdateBannerHeight+'px'});
                $('#icChatButton').css({'bottom': (consentManagerUpdateBannerHeight+50)+'px'});
                $('#back-to-top').css({'bottom': '30%'});
            } else {
                $('#conveythis-wrapper').css({'bottom': consentManagerHeight+'px'});
                $('#icChatButton').css({'bottom': (consentManagerHeight+50)+'px'});
                if($(window).width() <= 400) { 
                    $('#back-to-top').css({'bottom': (consentManagerHeight+110)+'px'});
                } else {
                    $('#back-to-top').css({'bottom': '30%'});
                }
            }
        }
        // -------------- End Re-adjust Widget Positions ----------------------
    }
}
