module.exports = class CartValidation {
    constructor(context, overlay) {
        this.$overlay = overlay;
        this.$cart_content = $('[data-cart-content]');
        this.$cart_totals_wrapper = $('.data-cart-totals-wrapper');
        this.context = context;
        this.validateCart();
    }
    disableCheckout() {
        this.$cart_totals_wrapper.find('[href="/checkout"]').attr('disabled', 'disabled');
        sessionStorage.removeItem('cart_was_validated');
    }
    enableCheckout() {
        this.$cart_totals_wrapper.find('[href="/checkout"]').removeAttr('disabled');
        sessionStorage.setItem('cart_was_validated', true);
        if (window.location.search.includes('redirect')) {
            window.location.href = "/checkout";
        }
    }
    validateCart() {
        console.log('validating');
        if (window.location.search.includes('redirect')) {
            window.modals.modals.CustomerValidation.RedirectCartModal.open();
        }
        this.disableCheckout();
        this.$overlay.show();
        $('[data-cart-validation-message]').html('');
        sessionStorage.setItem('cart-is-validated', false);
        let current_customer_id = $('body').data('customerId');
        if (current_customer_id === undefined || current_customer_id <= 0) {
            $( window ).on( "load", () => {
                this.$overlay.hide();
                $('[data-cart-validation-message]').html('<div class="info">Please <a href="/login.php?from=cart.php" data-open-login>login or register</a> to check out.</div>');
                $('[data-cart-validation-modal-message]').html('<div class="info">Please <a href="/login.php?from=cart.php" data-open-login>login or register</a> to check out.</div>');
                $('.modal-header').hide();
            });
        } else {
            let that = this;
            $.get('/api/storefront/carts').done(function (outer_response) {
                if (outer_response && outer_response.length && outer_response[0].id) {
                    console.log(outer_response[0].id);

                    // Sales Tax Adjustment script
                    if(that.context.themeSettings.enable_sales_tax_adjustment) {
                        $.ajax({
                            method: 'POST',
                            json: true,
                            "content-type": "application/json",
                            "url": window.SALES_TAX_ADJUSTMENT,
                            timeout: 60000,
                            success: function (response) {
                                console.log('Response from sales tax adjustment: ', response);
                                if (response["reload"]) {
                                    window.location.reload();
                                }
                                console.log('success tax adjustment')
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                console.log('Error - jqXHR:', jqXHR);
                                console.log('Error - textStatus:', textStatus);
                                console.error('Error - errorThrown:', errorThrown);
                                console.log('fail tax adjustment')
                            },
                            processData: false,
                            data: JSON.stringify({
                                cart_id: outer_response[0].id,
                                store_hash: window.STORE_HASH,
                                customer_id: current_customer_id
                            }),
                            dataType: "json"
                        });
                    }

                    $.ajax({
                        method: 'POST',
                        json: true,
                        "content-type": "application/json",
                        "url": window.API_CART_VALIDATION,
                        timeout: 60000,
                        success: function (response) {
                            //console.log(response);
                            if (response.status === "Success" && response.data && response.data.is_valid) {
                                sessionStorage.setItem('cart-is-validated', true);
                                that.enableCheckout();
                                if (!window.location.search.includes('redirect')) {
                                    $('#cart-full-modal-target').find('.modal-header').hide();
                                    $('#cart-full-modal-target').find('.modal-description-content').show();
                                }
                            } else if (response.status === "Error" && response.message) {
                                $('[data-cart-validation-message]').html('<div class="error">' + response.message + '</div>');
                                $('[data-cart-validation-modal-message]').html('<div class="error">Looks like you’ve already placed an order, <a href="/account.php?action=order_status">click here to view order status</a>.</div>');
                                $('.modal-header').hide();
                            } else {
                                $('[data-cart-validation-message]').html('<div class="error">Unable to validate cart. Please refresh this page or <a href="/contact-us">contact us</a> for support.</div>');
                                $('[data-cart-validation-modal-message]').html('<div class="error">Unable to validate cart. Please refresh this page or <a href="/contact-us">contact us</a> for support.</div>');
                                $('.modal-header').hide();
                            }
                            that.$overlay.hide();
                        },
                        error: function (error) {
                            //console.log(error);
                            $('[data-cart-validation-message]').html('<div class="error">Unable to validate cart. Please refresh this page or <a href="/contact-us">contact us</a> for support.</div>');
                            $('[data-cart-validation-modal-message]').html('<div class="error">Unable to validate cart. Please refresh this page or <a href="/contact-us">contact us</a> for support.</div>');
                            $('.modal-header').hide();
                            that.$overlay.hide();
                        },
                        processData: false,
                        data: JSON.stringify({
                            store_hash: window.STORE_HASH,
                            cart_id: outer_response[0].id,
                            customer_id: current_customer_id
                        })
                    });
                }
            });
        }
    }

}
