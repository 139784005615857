import ModalFactory from '../../../theme/global/modal';

export default class ApplicationModal {
    constructor(target, content) {
        this.Modal = new ModalFactory(target)[0];
        this.Modal.updateContent($(content).html());
        $(content).html('');
        this.callbacks = {
            willOpen: () => { },
            didOpen: () => { },
            willClose: () => { },
            didClose: () => { },
            willSubmit: () => { },
            didSubmit: () => { },
        };
        this.bindModal(target);

    }
    bindModal(target) {
        $(target).find('[data-action]:not([data-action="submit"])').on('click', (e) => {
            e.preventDefault();
            this.handleAction(e.target);
        });
    }
    handleAction(target) {
        console.error('handleAction Not Implemented', target);
    }
    open() {
        this.callbacks.willOpen();
        this.Modal.open({
            clearContent: false,
            pending: false,
        });
        window.modals.currently_open_modal = this;
        this.callbacks.didOpen();
    }
    close() {
        this.callbacks.willClose();
        this.Modal.close();
        window.modals.currently_open_modal = null;
        this.callbacks.didClose();
    }
    prev() {
        let prev_modal = window.modals.current_path[window.modals.current_path.indexOf(this) - 1];
        if (prev_modal) {
            prev_modal.open();
        }
    }
    next() {
        if (this.validate()) {
            let next_modal = window.modals.current_path[window.modals.current_path.indexOf(this) + 1];
            if (next_modal) {
                next_modal.open();
            } else {
                this.completePath();
            }
        }
    }
    showOverlay() {
        this.Modal.$overlay.show();
    }
    hideOverlay() {
        this.Modal.$overlay.hide();
    }
    endPath() {
        window.modals.currently_open_modal = null;
        window.modals.current_path = null;
        window.modals.onPathComplete = undefined;
        this.callbacks.willClose();
        this.Modal.close();
        this.callbacks.didClose();
    }
    completePath() {
        if (window.modals.onPathComplete) {
            window.modals.onPathComplete();
        }
        this.endPath();
    }
    validate() {
        return true;
    }
}
