import $ from 'jquery';

export default (eligibility_query, callback) => {
    sessionStorage.removeItem('validated_customer_id');
    $.ajax({
        "url": window.VALIDATE_CUSTOMER_API_ENDPOINT,
        "method": "POST",
        "timeout": 20000,
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        "data": JSON.stringify(eligibility_query),
        dataType: "json",
        processData: false,
    }).done(function (eligibility_response) {
        if (eligibility_response && eligibility_response.data) {
            window.modals.modals.CustomerValidation.RegisterCustomerModal.updateCustomerInfo(eligibility_response.data);
            callback({
                valid: true,
            });
        } else {
            callback({
                valid: false,
                error_message: eligibility_response.message,
            });
        }
    }).fail(function (response) {
        let eligibility_response = response.responseJSON;
        if (eligibility_response) {
            callback({
                valid: eligibility_response.eligible,
                error_message: eligibility_response.message,
            });
        } else {
            callback({
                valid: false,
                error_message: 'Unable to verify eligiblity.',
            });
        }
    });
}
