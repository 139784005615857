import FormModal from './FormModal';
import requestCreateCustomer from '../api/requestCreateCustomer';
import requestLoginCustomer from '../api/requestLoginCustomer';
import {getLocalStorageWithExpiry} from '../localStorage-with-expiry'

export default class RegisterCustomerModal extends FormModal {
  constructor(target, content) {
    super(target, content);
    this.target = target;
  }
  
  handleAction(target) {
    switch ($(target).data('action')) {
      case 'dismiss':
        this.completePath();
        break;
      case 'previous':
        window.modals.previousModal();
        break;
      default:
        console.error('no action implemented for: ', target);
    }
  }

  updateCustomerInfo() {    
    let field_to_element_map = {
      "first_name": "#register-first-name",
      "last_name": "#register-last-name",
      "phone": "#register-phone", 
      "email": "#register-email",
      "address_1": "#register-address1",
      "address_2": "#register-address2",
      "city": "#register-city",
      "state": "#register-state",
      "zipcode": "#register-zipcode",
      "registration_token": "#registration-token",
      "customer_group_id": "#register-customer-group"
    };
    let field_to_value_map = {
      "first_name": getLocalStorageWithExpiry('first_name'),
      "last_name": getLocalStorageWithExpiry('last_name'),
      "phone": getLocalStorageWithExpiry('phone'),
      "email": getLocalStorageWithExpiry('email'),
      "address_1":  getLocalStorageWithExpiry('address_1'),
      "address_2":  getLocalStorageWithExpiry('address_2'),
      "city":  getLocalStorageWithExpiry('city'),
      "state":  getLocalStorageWithExpiry('state'),
      "zipcode":  getLocalStorageWithExpiry('zipcode'),
      "registration_token":  getLocalStorageWithExpiry('registration_token'),
      "customer_group_id": getLocalStorageWithExpiry('customer_group_id'),
    }
    let field_to_readonly_map = {
      "first_name": false,
      "last_name": false,
      "phone": false,
      "email": false,
      "address_1":  true,
      "address_2": false,
      "city":  true,
      "state":  true,
      "zipcode":  true,
      "registration_token":  true,
      "customer_group_id": true,
    }

    Object.keys(field_to_element_map).forEach(field => {
      let element = $(this.target).find(field_to_element_map[field]);
      if (field_to_value_map[field] !== undefined) {
        let value = field_to_value_map[field];
        if(!value || value === "NULL") return;
        element.val(value);
        if(getLocalStorageWithExpiry('validation_type') === 'purl') return;
        if(element.prop("tagName") === 'SELECT') {
          element.prop("style", "pointer-events: none; background-color: #e9ecef; border-color: dimgray;");
        } else {
          element.prop('readonly', field_to_readonly_map[field]);
        }
      }
    });
  }

  validateForm(form_element, callback) {
    let password = $(form_element).find('[type=password]').val();
    let valid = true;
    let error_messages = [];
    if (password.length < 8) {
      valid = false;
      error_messages.push('Password must be longer than 8 characters.');
    }
    if (password.match(/\w/) && password.match(/\d/) && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/) && password.match(/[^a-zA-Z0-9 ]+/)) {
    } else {
      valid = false;
      error_messages.push('Password must contain at least one lower-case letter, upper-case letter, number, and special character (e.g. !@#$%).');
    }
    let error_message;
    if (error_messages.length) {
      error_message = error_messages.map(m => `<li>${m}</li>`).join('');
    }

    callback({
      valid,
      error_message,
    });
  }
  submitForm(callback) {
    let create_customer_request_object = {};

    let form_elements = $(this.target).find('#register-account').serializeArray();
    form_elements.forEach(element => create_customer_request_object[element.name] = element.value);

    //Get form field values from local storage
    const form_fields = JSON.parse(window.localStorage.getItem('modal_answers'));
    let requerst_form_fields = [];

    for(let i in form_fields){
      requerst_form_fields.push({ 
        name: i,
        value: form_fields[i]
      })
    }

    create_customer_request_object["register-form-fields"] = requerst_form_fields;
    create_customer_request_object["register-enable-review-abandoned-carts-emails"] = true;
    
    this.showOverlay();

    let request_createCustomer = {};
    request_createCustomer.store_hash = window.STORE_HASH;
    request_createCustomer.data = create_customer_request_object;
    
    requestCreateCustomer(request_createCustomer, (create_customer_response => {
      this.hideOverlay();

      if (create_customer_response && create_customer_response.status && create_customer_response.status.toLowerCase() === "success") {
        callback({
          success: true
        });
        window.setTimeout(() => {
          
          requestLoginCustomer(create_customer_request_object['register-email']
            , create_customer_request_object['register-password'], (response => {
            console.log('requestLoginCustomer response: ', response);
            if (response.success) {
              if( window.modals.initializing_action === 'register-customer' ) {
                window.location.href = "/landing/";
              } else if ( window.modals.initializing_action === 'add-to-cart'
                          || window.modals.initializing_action === 'add-from-landing-page-to-cart' ) {
                window.logged_in = true;
                $(window.targetButton).trigger('click');
              } else {
                window.location.reload();
              }
            } else {
              alert("Automatic login is disabled while the site is in preview mode. Please login when the page reloads.");
              window.location.href = "/login.php?from=landing/";
            }
          }));
        }, 500);
      } else {
        let error_message = create_customer_response.error_message;
        console.log("error_message", error_message);

        this.showErrorMessage(error_message);
        callback({
          success: false,
          error_message,
        });
      }
    }));
  }
}
