import InfoModal from './InfoModal';

export default class HeatWaterQuestionErrorModal extends InfoModal {
  constructor(target, content) {
    super(target, content);
    this.Modal.$modal.foundation({
      reveal: {
        bg_class: 'modal-background',
        dismiss_modal_class: 'modal-close',
        close_on_background_click: false,
        close_on_esc: false,
      }
    });
  }
}
