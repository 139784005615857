import FormModal from './FormModal';
import requestCreateCustomer from '../api/requestCreateCustomer';
import requestLoginCustomer from '../api/requestLoginCustomer';

export default class RegisterCustomerModal extends FormModal {
  constructor(target, content) {
    super(target, content);
    this.target = target;
  }
  handleAction(target) {
    switch ($(target).data('action')) {
      case 'dismiss':
        this.completePath();
        break;
      case 'previous':
        window.modals.previousModal();
        break;
      default:
        console.error('no action implemented for: ', target);
    }
  }


  updateCustomerInfo(customer) {
    let field_to_element_map = {
      "address_1": "#register-address1",
      "address_2": "#register-address2",
      "city": "#register-city",
      "state": "#register-state",
      "zipcode": "#register-zipcode",
      "registration_token": "#registration-token",
    };
    let field_to_value_map = {
      "address_1": {},
      "address_2": {},
      "city": {},
      "state": {},
      "zipcode": {},
      "registration_token": {}
    }

    Object.keys(field_to_element_map).forEach(field => {
      let element = $(this.target).find(field_to_element_map[field]);
      let value = customer[field];
      if (field_to_value_map[field] && field_to_value_map[field][customer[field]] !== undefined) {
        value = field_to_value_map[field][customer[field]];
      }
      element.val(value);
      element.attr('readonly', 'readonly');
    });
  }

  validateForm(form_element, callback) {
    let password = $(form_element).find('[type=password]').val();
    let valid = true;
    let error_messages = [];
    if (password.length < 8) {
      valid = false;
      error_messages.push('Password must be longer than 8 characters.');
    }
    if (password.match(/\w/) && password.match(/\d/) && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/) && password.match(/[^a-zA-Z0-9 ]+/)) {
    } else {
      valid = false;
      error_messages.push('Password must contain at least one lower-case letter, upper-case letter, number, and special character (e.g. !@#$%).');
    }
    let error_message;
    if (error_messages.length) {
      error_message = error_messages.map(m => `<li>${m}</li>`).join('');
    }

    callback({
      valid,
      error_message,
    });
  }
  submitForm(callback) {
    let create_customer_request_object = {};

    let form_elements = $(this.target).find('#register-account').serializeArray();
    form_elements.forEach(element => create_customer_request_object[element.name] = element.value);

    //Get form field values from local storage
    const form_fields = JSON.parse(window.localStorage.getItem('modal_answers'));
    let requerst_form_fields = [];

    for(let i in form_fields){
      requerst_form_fields.push({ 
        name: i,
        value: form_fields[i]
      })
    }

    create_customer_request_object["register-form-fields"] = requerst_form_fields;
    
    this.showOverlay();

    let request_createCustomer = {};
    request_createCustomer.store_hash = window.STORE_HASH;
    request_createCustomer.data = create_customer_request_object;
    
    requestCreateCustomer(request_createCustomer, (create_customer_response => {
      this.hideOverlay();
      //console.log('create_customer_response', create_customer_response);
      //console.log('status', create_customer_response.status);

      //{"status":"Error","data":null,"message":"Error registering account."}
      if(create_customer_response && create_customer_response.success == false){
        //console.log("error_message", error_message);
        let error_message = create_customer_response.error_message;
        this.showErrorMessage(error_message);
        callback({
          success: false,
          error_message,
        });
      } else if(create_customer_response && (create_customer_response.status).toLowerCase() === "success"){
        callback({
          success: true
        });
        window.setTimeout(() => {
          requestLoginCustomer(create_customer_request_object, (response => {
            //console.log('response', response);
            if (response.success) {
              window.location.reload();
            } else {
              alert("Automatic login is disabled while the site is in preview mode. Please login when the page reloads.");
              window.location.href = "/login.php?from=shop";
            }
          }));
        }, 500);
      } else {
        //console.log("error_message", error_message);
        let error_message = create_customer_response.error_message;
        this.showErrorMessage(error_message);
        callback({
          success: false,
          error_message,
        });
      }

      // if (create_customer_response && (create_customer_response.status).toLowerCase() === "success") {
      //   callback({
      //     success: true
      //   });
      //   window.setTimeout(() => {
      //     requestLoginCustomer(create_customer_request_object, (response => {
      //       //console.log('response', response);
      //       if (response.success) {
      //         window.location.reload();
      //       } else {
      //         alert("Automatic login is disabled while the site is in preview mode. Please login when the page reloads.");
      //         window.location.href = "/login.php?from=shop";
      //       }
      //     }));
      //   }, 500);
      // } else {
      //   console.log("error_message", error_message);
      //   let error_message = create_customer_response.error_message;
      //   this.showErrorMessage(error_message);
      //   callback({
      //     success: false,
      //     error_message,
      //   });
      // }
    }));
  }
}
