import $ from 'jquery';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import compareProducts from './global/compare-products';
import privacyCookieNotification from './global/cookieNotification';
import maintenanceMode from './global/maintenanceMode';
import carousel from './common/carousel';
import 'lazysizes';
import sweetAlert from './global/sweet-alert';
import svgInjector from './global/svg-injector';

/*=========================================
=            www.themevale.com            =
=========================================*/

import themevale_Global from './themevale/themevale_Global';
import themevale_AddToCart from './themevale/themevale_AddToCart';
import themevale_RecentlyBought from './themevale/themevale_RecentlyBought';
import themevale_Sticky from './themevale/themevale_StickyNavigation';
    window.themevale_Sticky = themevale_Sticky;
import themevaleMenu from './themevale/themevale_MegaMenu';
    window.themevaleMenu = themevaleMenu;
import themevaleNewsletterPopup from './themevale/themevale_NewsletterPopup';
    window.themevaleNewsletterPopup = themevaleNewsletterPopup;
/*=====  End of www.themevale.com  ======*/

// Custom - AMCG Imports
import Modals from './custom/Modals';
import {setLocalStorageWithExpiry, getLocalStorageWithExpiry} from './custom/localStorage-with-expiry';
import OneTrustCookieScript from './custom/OneTrustCookieScript';

export default class Global extends PageManager {
    onReady() {
        // Only load visible elements until the onload event fires,
        // after which preload nearby elements.
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.loadMode = 1;

        cartPreview(this.context);

        quickSearch();
        currencySelector();
        foundation($(document));
        quickView(this.context);
        compareProducts(this.context.urls);
        carousel();
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        maintenanceMode(this.context.maintenanceMode);
        sweetAlert();
        svgInjector();

        themevale_Global();
        themevale_AddToCart();
        themevale_RecentlyBought(this.context); 

        /************************** Custom ***************************/
        // Set up value of the logo url and body element attribute 
        // to show and hide particular kit pick nav element
        let customer_group_id =  parseInt(getLocalStorageWithExpiry('customer_group_id', '-1'));
        let is_logged_in = parseInt($('[data-customer-id]').data('customerId')) > 0;
        if (is_logged_in) {
            customer_group_id = parseInt($('[data-customer-group-id]').data('customerGroupId'));
        }

        let logoURL = '/';
        if (customer_group_id === 1) {
            $('body').attr('data-kit', 'single water kit');
            logoURL = '/new-upgraded-single-bath-save-energy-water-kit/';
        } else if (customer_group_id === 2){
            $('body').attr('data-kit', 'double water kit');
            logoURL = '/new-upgraded-double-bath-save-energy-water-kit/';
        } else if (customer_group_id === 3) {
            $('body').attr('data-kit', 'full price water kit');
            logoURL = '/full-price-single-bath-save-energy-water-kit/';
        }

        $('.header-logo-anchor').each((i, el) => {
            el.href= logoURL;
        })

        $('#header-sticky-logo-anchor').attr('href', logoURL);

        // Show shopping cart when customer group id is set
        let $shoppingCart = $('.navUser-item.navUser-item--cart.themevale_cart');
        let $mobileShoppingCart = $('.items.item--cart.themevale_cart');
        if(customer_group_id !== -1) {
            $shoppingCart.removeClass('hidden');
            $mobileShoppingCart.removeClass('hidden');
        }

        // Show registration button and related elements
        let registration_token = getLocalStorageWithExpiry('registration_token', null);
        if(registration_token && !is_logged_in) {
            $('[data-register-customer]').show();
            $('.navUser-or').show();
            $('.new-customer').show();
        }

        // AMCG modals
        window.modals = new Modals(this.context);
        window.modals.loadCustomerData();

        // Footer date
        $('#year').text((new Date()).getFullYear())

        // OneTrust script
        if(this.context.themeSettings.enable_onetrust_cookie_consent_manager) {
            console.log('Enabled onetrust script');
            let oneTrustCookieScript = new OneTrustCookieScript();
        }
    }
}
