import InfoModal from './ApplicationModal';

export default class HeatQuestionInfoModal extends InfoModal {
  constructor(target, content) {
    super(target, content);
  }
  handleAction(target) {
    switch ($(target).data('action')) {
      case 'dismiss':
        this.completePath();
        break;
      default:
        console.error('no action implemented for: ', target);
    }
  }
}
