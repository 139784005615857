import $ from 'jquery';

const getRemoteCartId = (callback) => {
    $.ajax({
        type: "GET",
        dataType: "json",
        url: '/api/storefront/carts',
        credentials: "same-origin",
        success: function (r) {
            if (r && r.length) {
                callback(r[0].id);
            } else {
                callback(null);
            }
        },
        error: function (e) {
            console.error('getRemoteCartId: ', e);
            callback(null);
        }
    });
}


const addToCart = (product_id, quantity, option_selections, callback) => {
    option_selections = option_selections || [];
    let line_items = [{
        productId: product_id,
        quantity: quantity,
        option_selections
    }];
    function sendXHR(url) {
        $.ajax({
            type: "POST",
            dataType: "json",
            data: JSON.stringify({
                lineItems: line_items,
            }),
            url: url,
            timeout: 20000,
            xhrFields: {
                withCredentials: true
            },
            success: function (response) {
                callback ? callback(response) : null;
            },
            error: function (e) {
                console.error('addToCart: ', e);
                callback ? callback(false) : null;
            }
        });
    }
    getRemoteCartId(function (id) {
        if (id) {
            sendXHR('/api/storefront/carts/' + id + '/items');
        } else {
            sendXHR('/api/storefront/cart');
        }
    });

}

const addProductsToCart = (line_items, callback) => {
    function sendXHR(url) {
        $.ajax({
            type: "POST",
            dataType: "json",
            data: JSON.stringify({
                lineItems: line_items,
            }),
            url: url,
            timeout: 20000,
            xhrFields: {
                withCredentials: true
            },
            success: function (response) {
                callback ? callback(response) : null;
            },
            error: function (e) {
                console.error('addProductsToCart: ', e);
                callback ? callback(false) : null;
            }
        });
    }
    getRemoteCartId(function (id) {
        if (id) {
            sendXHR('/api/storefront/carts/' + id + '/items');
        } else {
            sendXHR('/api/storefront/cart');
        }
    });

}

export { addToCart, addProductsToCart };
