
export default class OneTrust {
    constructor() {
        this.formattedSiteName = window.location.hostname.toUpperCase().replace('.COM', '');
        this.cookieConsentToggleState = 'na';

        let that = this;
        window.OptanonWrapper = function() {
            const $ccpaLink = $('#ccpa-opt-out-request-form');
            $ccpaLink.on('click', (e) => {
                that.removeDoNotSellRedirect(e);
            });

            const $sellPersonalInfoToggle = $('#ot-pc-content .ot-tgl .ot-switch');
            $sellPersonalInfoToggle.on('click', (e) => {
                that.toggleSellPersonalInfoToggle(e);
            });

            const $savePreferencesBtn = $('.save-preference-btn-handler');
            $savePreferencesBtn.on('click', (e) => {
                that.handleCookieConsentClicks(e);
            });
        }
        
        return this;
    }

    removeDoNotSellRedirect(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        window.OneTrust.ToggleInfoDisplay();

        const otGeoState = window.OneTrust.getGeolocationData().state.toLowerCase();
        this.trackCookieConsentClicks({
            event: 'send-VPV',
            'vpv-name': `/vpv/de/modal/cookie-settings/load/do-not-sell-or-share-my-personal-information/${this.formattedSiteName}/na/${otGeoState}`
        });
    }

    toggleSellPersonalInfoToggle(e) {
        e.stopImmediatePropagation();
        const toggleEl = $(e.target);
        this.cookieConsentToggleState = toggleEl.prop("checked") ? 'on' : 'off';
    }

    handleCookieConsentClicks(e) {
        e.stopImmediatePropagation();
        this.trackCookieConsentClicks({
            event: 'send-VPV',
            'vpv-name': `/vpv/de/modal/cookie-settings/onclick/do-not-sell-or-share-my-personal-information/${this.formattedSiteName}/na/ca/${this.cookieConsentToggleState}`,
        });
    }

    trackCookieConsentClicks(obj) {
        if (window.dataLayer && Array.isArray(window.dataLayer)) {
            window.dataLayer.push(obj);
        }
    }
}