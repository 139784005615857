import ApplicationModal from './ApplicationModal';

export default class FormModal extends ApplicationModal {
    constructor(target, content) {
        super(target, content);
        this._bindModal(target);
        this.is_form_modal = true;
        this.target = target;
        this.standard_error_message_start = 'Error:';
        this.standard_error_message_end = '<div class="error-contact-us">Please <a href="/contact-us">contact us</a> if you need assistance.</div>';
    }
    _bindModal(target) {
        $(target).find('form').on('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.callbacks.willSubmit();
            this.onFormSubmit(e.target);
        });
    }
    onFormSubmit(form_element) {
        this.showOverlay();
        this.validateForm(form_element, (validation_response) => {
            super.hideOverlay();
            if (validation_response && validation_response.valid) {
                this.submitForm((submit_response) => {
                    if (submit_response.success) {
                        window.modals.nextModal();
                        this.callbacks.didSubmit();
                    } else {
                        let error_message = submit_response.error_message;
                        this.showErrorMessage(error_message);
                    }
                })
            } else {
                let error_message;
                if (validation_response) {
                    error_message = validation_response.error_message;
                }
                if (error_message === undefined) {
                    error_message = 'An error occured while verifying this information.'
                }
                this.showErrorMessage(error_message);
            }
        });
    }
    submitForm(callback) {
        console.error('Default submit form enabled');
        callback({
            success: true,
        });
    }
    validateForm(form_element, callback) {
        console.error('default form submit enabled for', form_element);
        callback({
            valid: true,
        });
    }
    showErrorMessage(error_message) {
        if (error_message === undefined) {
            error_message = 'Something went wrong submitting this information.'
        }
        error_message = `<div class="message">${error_message}</div><div>${this.standard_error_message_end}</div>`;
        $(this.target).find('[data-modal-errors]').addClass('visible').html('<div class="error">' + error_message + '</div>');
    }
    handleAction(target) {
        switch ($(target).data('action')) {
            case 'next':
                window.modals.nextModal();
                break;
            case 'previous':
                window.modals.previousModal();
                break;
            case 'dismiss':
                this.close();
                break;
            default:
                console.error1('no action defined for: ', target);
        }
    }
    checkValidity() {
        return $(this.target).find('form')[0].checkValidity();
    }
    reportValidity() {
        return $(this.target).find('form')[0].reportValidity();
    }
}
