import PageManager from './page-manager';
import $ from 'jquery';
import _ from 'lodash';
import giftCertCheck from './common/gift-certificate-validator';
import utils from '@bigcommerce/stencil-utils';
import ShippingEstimator from './cart/shipping-estimator';
import { defaultModal } from './global/modal';
import swal from 'sweetalert2';
import QuickEditCart from './themevale/themevale_QuickEditCart';

export default class Cart extends PageManager {
    constructor(context) {
        super(context);
    }

    onReady() {
        this.$cartContent = $('[data-cart-content]');
        this.$cartMessages = $('[data-cart-status]');
        this.$cartTotals = $('[data-cart-totals]');
        this.$overlay = $('[data-cart] .loadingOverlay');
        this.lineIdToValidateRebateResponseLine = {};

        $('body').on('cart-quantity-update', (event, quantity) => {
            $('.cart-quantity')
                .text(quantity)
                .toggleClass('countPill--positive', quantity > 0);
        });

        this.applyRebates(false);

        this.callbacks = {
            willUpdate: () => { },
            didUpdate: () => { },
        };

        this.bindEvents();
        QuickEditCart(this.context);

        if ($('body').hasClass('page-type-cart')) {
            const $cart = $('[data-cart-preview]');

            $cart.on('click', event => {
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $('[data-cart]').offset().top,
                }, 700);
            });
        }
    }

    disableCheckout() {
        $('#checkout-button').attr('disabled', 'disabled');
    }

    cartUpdate($target) {
        const itemId = $target.data('cartItemid');
        const $el = $(`#qty-${itemId}`);
        const oldQty = parseInt($el.val(), 10);
        const maxQty = parseInt($el.data('quantityMax'), 10) ? parseInt($el.data('quantityMax'), 10) : Infinity;
        const minQty = parseInt($el.data('quantityMin'), 10) ? parseInt($el.data('quantityMin'), 10) : 1;
        const minError = $el.data('quantityMinError');
        const maxError = $el.data('quantityMaxError');
        const quantityStep = $el.data('quantityStep') ? parseInt($el.data('quantityStep'), 10) : 1;
        const newQty = $target.data('action') === 'inc' ? oldQty + quantityStep : oldQty - quantityStep;

        // Update state of increment and decrement buttons
        let incButtonEnabled = true;
        let $incButton;
        if($target.data('action') === 'inc') {
            $incButton = $target;
        } else {
            $incButton = $target.siblings('button[data-action]');
        }

        // check to see if qty is above max
        if(newQty + quantityStep > maxQty) {
            incButtonEnabled = false;
        }

        if(incButtonEnabled) { $incButton.prop('disabled', false);}
        else { $incButton.prop('disabled', true);}

        // Does not qualify for min/max quantity
        if (newQty < minQty) {
            return swal({
                text: minError,
                type: 'error',
            });
        } else if (maxQty > 0 && newQty > maxQty) {
            return swal({
                text: maxError,
                type: 'error',
            });
        }

        this.$overlay.show();

        utils.api.cart.itemUpdate(itemId, newQty, (err, response) => {
            this.$overlay.hide();

            if (response.data.status === 'succeed') {
                // if the quantity is less then minQty, remove the row
                const remove = (newQty < minQty);// if the quantity is changed "1" from "0", we have to remove the row.

                // this.refreshContent(remove);
                this.applyRebates(remove);
            } else {
                $el.val(oldQty);
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    cartUpdateQtyTextChange($target, preVal = null) {
        const itemId = $target.data('cartItemid');
        const $el = $(`#qty-${itemId}`);
        const maxQty = parseInt($el.data('quantityMax'), 10);
        const minQty = parseInt($el.data('quantityMin'), 10);
        const oldQty = preVal !== null ? preVal : minQty;
        const minError = $el.data('quantityMinError');
        const maxError = $el.data('quantityMaxError');
        const newQty = parseInt(Number($el.attr('value')), 10);
        let invalidEntry;
        // Does not quality for min/max quantity
        if (!newQty) {
            invalidEntry = $el.attr('value');
            $el.val(oldQty);
            return swal({
                text: `${invalidEntry} is not a valid entry`,
                type: 'error',
            });
        } else if (newQty < minQty) {
            $el.val(oldQty);
            return swal({
                text: minError,
                type: 'error',
            });
        } else if (maxQty > 0 && newQty > maxQty) {
            $el.val(oldQty);
            return swal({
                text: maxError,
                type: 'error',
            });
        }

        this.$overlay.show();
        utils.api.cart.itemUpdate(itemId, newQty, (err, response) => {
            this.$overlay.hide();

            if (response.data.status === 'succeed') {
                // if the quantity is changed "1" from "0", we have to remove the row.
                const remove = (newQty === 0);

                // this.refreshContent(remove);
                this.applyRebates(remove);
            } else {
                $el.val(oldQty);
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    cartRemoveItem(itemId) {
        this.$overlay.show();
        utils.api.cart.itemRemove(itemId, (err, response) => {
            if (response.data.status === 'succeed') {
                // this.refreshContent(true);
                this.applyRebates(true);
            } else {
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    cartEditOptions(itemId) {
        const modal = defaultModal();
        const options = {
            template: 'cart/modals/configure-product',
        };

        modal.open();

        utils.api.productAttributes.configureInCart(itemId, options, (err, response) => {
            modal.updateContent(response.content);

            this.bindGiftWrappingForm();
        });

        utils.hooks.on('product-option-change', (event, option) => {
            const $changedOption = $(option);
            const $form = $changedOption.parents('form');
            const $submit = $('input.button', $form);
            const $messageBox = $('.alertMessageBox');
            const item = $('[name="item_id"]', $form).attr('value');

            utils.api.productAttributes.optionChange(item, $form.serialize(), (err, result) => {
                const data = result.data || {};

                if (err) {
                    swal({
                        text: err,
                        type: 'error',
                    });
                    return false;
                }

                if (data.purchasing_message) {
                    $('p.alertBox-message', $messageBox).text(data.purchasing_message);
                    $submit.prop('disabled', true);
                    $messageBox.show();
                } else {
                    $submit.prop('disabled', false);
                    $messageBox.hide();
                }

                if (!data.purchasable || !data.instock) {
                    $submit.prop('disabled', true);
                } else {
                    $submit.prop('disabled', false);
                }
            });
        });
    }

    refreshContent(remove, reload = false) {
        const $cartItemsRows = $('[data-item-row]', this.$cartContent);
        const $cartPageTitle = $('[data-cart-page-title]');
        const options = {
            template: {
                content: 'cart/content',
                totals: 'cart/totals',
                pageTitle: 'cart/page-title',
                statusMessages: 'cart/status-messages',
            },
        };

        this.$overlay.show();

        // Remove last item from cart? Reload
        if (reload || (remove && $cartItemsRows.length === 1)) {
            return window.location.reload();
        }

        utils.api.cart.getContent(options, (err, response) => {
            this.$cartContent.html(response.content);
            this.$cartTotals.html(response.totals);
            this.$cartMessages.html(response.statusMessages);

            $cartPageTitle.replaceWith(response.pageTitle);
            this.addRebateMessaging();
            this.bindEvents();
            this.$overlay.hide();

            const quantity = $('[data-cart-quantity]', this.$cartContent).data('cartQuantity') || 0;

            $('body').trigger('cart-quantity-update', quantity);
        });
    }

    bindCartEvents() {
        const debounceTimeout = 400;
        const cartUpdate = _.bind(_.debounce(this.cartUpdate, debounceTimeout), this);
        const cartUpdateQtyTextChange = _.bind(_.debounce(this.cartUpdateQtyTextChange, debounceTimeout), this);
        const cartRemoveItem = _.bind(_.debounce(this.cartRemoveItem, debounceTimeout), this);
        let preVal;

        // cart update
        $('[data-cart-update]', this.$cartContent).on('click', event => {
            const $target = $(event.currentTarget);
            event.preventDefault();

            // update cart quantity
            cartUpdate($target);
        });

        // cart qty manually updates
        $('.cart-item-qty-input', this.$cartContent).on('focus', () => {
            preVal = this.value;
        }).change(event => {
            const $target = $(event.currentTarget);
            event.preventDefault();

            // update cart quantity
            cartUpdateQtyTextChange($target, preVal);
        });

        $('.cart-remove', this.$cartContent).on('click', event => {
            const itemId = $(event.currentTarget).data('cartItemid');
            const string = $(event.currentTarget).data('confirmDelete');
            swal({
                text: string,
                type: 'warning',
                showCancelButton: true,
            }).then(() => {
                // remove item from cart
                cartRemoveItem(itemId);
            });
            event.preventDefault();
        });

        $('[data-item-edit]', this.$cartContent).on('click', event => {
            const itemId = $(event.currentTarget).data('itemEdit');

            event.preventDefault();
            // edit item in cart
            this.cartEditOptions(itemId);
        });
    }

    bindPromoCodeEvents() {
        const $couponContainer = $('.coupon-code');
        const $couponForm = $('.coupon-form');
        const $codeInput = $('[name="couponcode"]', $couponForm);

        $('.coupon-code-add').on('click', event => {
            event.preventDefault();

            $(event.currentTarget).hide();
            $couponContainer.show();
            $('.coupon-code-cancel').show();
            $codeInput.trigger('focus');
        });

        $('.coupon-code-cancel').on('click', event => {
            event.preventDefault();

            $couponContainer.hide();
            $('.coupon-code-cancel').hide();
            $('.coupon-code-add').show();
        });

        $couponForm.on('submit', event => {
            const code = $codeInput.val();

            event.preventDefault();

            // Empty code
            if (!code) {
                return swal({
                    text: $codeInput.data('error'),
                    type: 'error',
                });
            }

            utils.api.cart.applyCode(code, (err, response) => {
                if (response.data.status === 'success') {
                    // this.refreshContent();
                    this.applyRebates();
                } else {
                    swal({
                        text: response.data.errors.join('\n'),
                        type: 'error',
                    });
                }
            });
        });
    }

    bindGiftCertificateEvents() {
        const $certContainer = $('.gift-certificate-code');
        const $certForm = $('.cart-gift-certificate-form');
        const $certInput = $('[name="certcode"]', $certForm);

        $('.gift-certificate-add').on('click', event => {
            event.preventDefault();
            $(event.currentTarget).toggle();
            $certContainer.toggle();
            $('.gift-certificate-cancel').toggle();
        });

        $('.gift-certificate-cancel').on('click', event => {
            event.preventDefault();
            $certContainer.toggle();
            $('.gift-certificate-add').toggle();
            $('.gift-certificate-cancel').toggle();
        });

        $certForm.on('submit', event => {
            const code = $certInput.val();

            event.preventDefault();

            if (!giftCertCheck(code)) {
                return swal({
                    text: $certInput.data('error'),
                    type: 'error',
                });
            }

            utils.api.cart.applyGiftCertificate(code, (err, resp) => {
                if (resp.data.status === 'success') {
                    // this.refreshContent();
                    this.applyRebates(remove);
                } else {
                    swal({
                        text: resp.data.errors.join('\n'),
                        type: 'error',
                    });
                }
            });
        });
    }

    bindGiftWrappingEvents() {
        const modal = defaultModal();

        $('[data-item-giftwrap]').on('click', event => {
            const itemId = $(event.currentTarget).data('itemGiftwrap');
            const options = {
                template: 'cart/modals/gift-wrapping-form',
            };

            event.preventDefault();

            modal.open();

            utils.api.cart.getItemGiftWrappingOptions(itemId, options, (err, response) => {
                modal.updateContent(response.content);

                this.bindGiftWrappingForm();
            });
        });
    }

    bindGiftWrappingForm() {
        $('.giftWrapping-select').on('change', event => {
            const $select = $(event.currentTarget);
            const id = $select.val();
            const index = $select.data('index');

            if (!id) {
                return;
            }

            const allowMessage = $select.find(`option[value=${id}]`).data('allowMessage');

            $(`.giftWrapping-image-${index}`).hide();
            $(`#giftWrapping-image-${index}-${id}`).show();

            if (allowMessage) {
                $(`#giftWrapping-message-${index}`).show();
            } else {
                $(`#giftWrapping-message-${index}`).hide();
            }
        });

        $('.giftWrapping-select').trigger('change');

        function toggleViews() {
            const value = $('input:radio[name ="giftwraptype"]:checked').val();
            const $singleForm = $('.giftWrapping-single');
            const $multiForm = $('.giftWrapping-multiple');

            if (value === 'same') {
                $singleForm.show();
                $multiForm.hide();
            } else {
                $singleForm.hide();
                $multiForm.show();
            }
        }

        $('[name="giftwraptype"]').on('click', toggleViews);

        toggleViews();
    }

    bindCheckoutLangSet() {
        const $checkout_button = $('#checkout-button');

        $checkout_button.on('click', event => {
            event.preventDefault();

            const $checkout_button = $(event.currentTarget);
            if($checkout_button.attr('disabled') === 'disabled'){
                return;
            }

            const paramsString = window.location.search;
            const searchParams = new URLSearchParams(paramsString);
            if(searchParams.has("locale") && searchParams.get("locale") === "es") {
                // console.log('change lang on click');
                document.location.href='/checkout?locale=es';
                return;
            }

            document.location.href='/checkout';
        });
    }

    bindEvents() {
        this.bindCartEvents();
        this.bindPromoCodeEvents();
        this.bindGiftWrappingEvents();
        this.bindGiftCertificateEvents();
        this.bindCheckoutLangSet();

        // initiate shipping estimator module
        this.shippingEstimator = new ShippingEstimator($('[data-shipping-estimator]'));
    }

    // Get cart line items from storefront API.
    async fetchCartLineItem(cart, item, method, body) {
        const fetchOptions = {
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };
        let url = `/api/storefront/carts/${cart.id}/items`;
        if (method == 'PUT') {
            url += `/${item.id}`;
        }
        return fetch(url, fetchOptions);
    }

    // Update existinng cart line item using storefront API.
    async updateCartLineItem(cart, item) {
        let body = {
            "lineItem": {
                "productId": item.productId,
                "variantId": item.variantId,
                "quantity": item.quantity,
                "optionSelections": item.options.map(option => ({
                    optionId: option.nameId,
                    optionValue: (option.valueId != null ? option.valueId : option.value)
                }))
            },
            "locale": "en"
        };
        return this.fetchCartLineItem(cart, item, 'PUT', body);
    }

    // Add a new cart line item using storefront API.
    async addCartLineItem(cart, item) {
        let body = {
            "lineItems": [{
                "productId": item.productId,
                "variantId": item.variantId,
                "quantity": item.quantity,
                "optionSelections": item.options.map(option => ({
                    optionId: option.nameId,
                    optionValue: (option.valueId != null ? option.valueId : option.value)
                }))
            }]
        };
        return this.fetchCartLineItem(cart, item, 'POST', body);
    }

    // Split single mixed eligiblity line into eligible and ineligible lines.
    async splitLine(cart, cartAction, rebateProductOptionData) {
        // Create a copy of line 1 so we can send new version to updateCartLineItem with new quantity.
        let line1Copy = JSON.parse(JSON.stringify(cartAction.line));
        // Make sure rebate option is set to yes for line 1. 
        // This will be needed if there was a previous split and user removes eligible line but leaves ineligible lines as rebate option will be no for this lines.
        line1Copy.options = line1Copy.options.map((o, i) => {
            if (o.name == rebateProductOptionData.instantRebateOptionName) {
                o.valueId = rebateProductOptionData.productIdAnswerObjMapping[line1Copy.productId][rebateProductOptionData.instantRebateOptionYesValue];
            }
            return o;
        });

        // Create copy of line 2 so we can create a new line item using addCartLineItem.
        let line2Copy = JSON.parse(JSON.stringify(cartAction.line));
        // Set rebate option of this line to no.
        line2Copy.options = line2Copy.options.map((o, i) => {
            if (o.name == rebateProductOptionData.instantRebateOptionName) {
                o.valueId = rebateProductOptionData.productIdAnswerObjMapping[line2Copy.productId][rebateProductOptionData.instantRebateOptionNoValue];
            }
            return o;
        });

        // Set quantity of each line according to cartAction data object set earlier. 
        line1Copy.quantity = cartAction.data[0];
        line2Copy.quantity = cartAction.data[1];

        // Update line item 1 and wait for result.
        await this.updateCartLineItem(cart, line1Copy);
        // Add line item 2.
        return this.addCartLineItem(cart, line2Copy);
    }

    // Get line id to local line data map.
    getLineIdToLocalLineData(lines, rebateProductOptionData) {
        return lines.reduce((acc, cur) => {
            if (cur.options) {
                let lineRebateOptions = cur.options.filter(o => o.name == rebateProductOptionData.instantRebateOptionName);
                if (lineRebateOptions.length) {
                    let lineRebateOption = lineRebateOptions[0];
                    acc[cur.id] = {
                        line: cur,
                        rebateStatus: lineRebateOption.value == rebateProductOptionData.instantRebateOptionYesValue
                    };
                }
            }
            return acc;
        }, {});
    }

    // Get cart actions from validate rebate response lines.
    getCartActions(validateRebateResponseLines, lines, rebateProductOptionData) {

        let cartActions = [];

        // Create map of line to local rebate status (current local status, not remote eligiblity)
        const lineIdToLocalLineData = this.getLineIdToLocalLineData(lines, rebateProductOptionData);

        // Go through each validate rebate response line and assign cart action if necessary.
        validateRebateResponseLines.forEach(validateRebateResponseLine => {

            // Get line data from lineIdToLocalLineData object.
            let localLineData = lineIdToLocalLineData[validateRebateResponseLine.line_id];

            // Exit if local line data undefined.
            if (localLineData == undefined) {
                console.error('missing local line data', lineIdToLocalLineData, validateRebateResponseLine.line_id);
                return;
            }

            // Get line from line data
            let line = localLineData.line;

            // Not eligible for any rebates.
            if (validateRebateResponseLine.quantity_eligible == 0) {
                // See if we local rebate status is true - if so, need to update to false.
                if (localLineData.rebateStatus == true) {
                    cartActions.push({ action: 'updateRebate', line, data: rebateProductOptionData.instantRebateOptionNoValue });
                }
            }
            // Eligible for partial rebates. Split out into eligible and ineligible lines.
            else if (validateRebateResponseLine.quantity_eligible < validateRebateResponseLine.quantity_requested) {
                // Store quantity for each line in data property for later use.
                cartActions.push({
                    action: 'split',
                    line,
                    data: [
                        validateRebateResponseLine.quantity_eligible,
                        (validateRebateResponseLine.quantity_requested - validateRebateResponseLine.quantity_eligible)
                    ]
                });
            }
            // Eligible for all rebates.
            else if (validateRebateResponseLine.quantity_eligible >= validateRebateResponseLine.quantity_requested) {
                // See if local status is false - if so, we need to update to true.
                if (localLineData.rebateStatus == false) {
                    cartActions.push({ action: 'updateRebate', line, data: rebateProductOptionData.instantRebateOptionYesValue });
                }
            }
        });
        return cartActions;
    }



    // Update line rebate
    async updateRebate(cart, cartAction, rebateProductOptionData) {
        // Create copy of line so we can send new version to updateCartLineItem.
        let lineCopy = JSON.parse(JSON.stringify(cartAction.line));
        // Set rebate status according to cartAction.data - will be rebateProductOptionData.(instantRebateOptionYesValue|instantRebateOptionNoValue)
        lineCopy.options = lineCopy.options.map((o, i) => {
            if (o.name == rebateProductOptionData.instantRebateOptionName) {
                o.valueId = rebateProductOptionData.productIdAnswerObjMapping[lineCopy.productId][cartAction.data];
            }
            return o;
        });
        // Update cart line item.
        return this.updateCartLineItem(cart, lineCopy);
    }

    // Apply rebates - intercepts refreshContent call, cals and passes through 'remove' param to this refreshContent on exit
    async applyRebates(applyRemoveForRefreshContent) {

        // Show loading screen.
        this.$overlay.show();

        // Get cart contents.
        const cart = await this.getCart();

        // console.log({cart})

        // Exit if cart or cart line items not defined.
        if (cart == undefined || cart.lineItems == undefined || cart.lineItems.physicalItems == undefined) {
            return this.refreshContent(true);
        }

        // Get cart lines.
        const lines = cart.lineItems.physicalItems;

        // Get rebate product option data from lines.
        const rebateProductOptionData = await this.getRebateProductOptionData(lines);

        // console.log({rebateProductOptionData})
        
        // Get validateRebates response from API.
        const validateRebateResponseLines = await this.validateRebates(cart);

        // console.log({validateRebateResponseLines})

        
        // Create object mapping of lineId to validateRebateResponseLine value.
        this.lineIdToValidateRebateResponseLine = validateRebateResponseLines.reduce((acc, curr) => {
            acc[curr.line_id] = curr;
            return acc;
        }, {});

        // console.log({'lineIdToValidateRebateResponseLine': this.lineIdToValidateRebateResponseLine})

        // DR (Demand Response) = CS (byot)

        // Get Non DR sku id for product
        const drSku_NonDRSkuIdMapping = await this.getDRSkuToNonDRSkuIdMapping(lines);
        // console.log({drSku_NonDRSkuIdMapping});

        // If no DR skus skip else change DR skus to non DR skus with rebate applied and then reload page
        
        if(drSku_NonDRSkuIdMapping) {
            let drToNonDRoperations = [];

            lines.forEach(currLine => {
                // If line is an ineligible DR line
                if(drSku_NonDRSkuIdMapping[currLine.sku] && this.lineIdToValidateRebateResponseLine[currLine.id].quantity_eligible === 0) { 
                    // change line to rebate id and reload
                    let body = {
                        "lineItem": {
                            "productId": currLine.productId,
                            "variantId": drSku_NonDRSkuIdMapping[currLine.sku],
                            "quantity": currLine.quantity,
                            "optionSelections": currLine.options.map(option => {
                                if(option.name === rebateProductOptionData.instantRebateOptionName) {
                                    return {
                                        optionId: option.nameId,
                                        optionValue: rebateProductOptionData.productIdAnswerObjMapping[currLine.productId].Yes
                                    }
                                }
    
                                return {
                                        optionId: option.nameId,
                                        optionValue: (option.valueId != null ? option.valueId : option.value)
                                }
                            })
                        },
                        "locale": "en"
                    };
                    
                    let url = `/api/storefront/carts/${cart.id}/items/${currLine.id}?include=lineItems.physicalItems.options`;
                    let fetchOptions = {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    };
                    
                    drToNonDRoperations.push({'url': url, 'options':fetchOptions});
                }
            });

            // console.log('drToNonDRoperations: ', drToNonDRoperations);
            if(drToNonDRoperations.length) {

                await drToNonDRoperations.reduce(async (acc, cur) => {
                    await acc;
                    return fetch(cur.url, cur.options);
                }, Promise.resolve());

                return window.location.reload();
            }
        }
        

        // Determine what actions to take for each line.
        const cartActions = this.getCartActions(validateRebateResponseLines, lines, rebateProductOptionData);

        // console.log({cartActions})

        // Go through each cart action, using a Promise reducer to wait for previous action to complete before continuing.
        await cartActions.reduce(async (acc, cartAction) => {
            await acc;
            switch (cartAction.action) {
                case 'split': {
                    return this.splitLine(cart, cartAction, rebateProductOptionData);
                }
                case 'updateRebate': {
                    return this.updateRebate(cart, cartAction, rebateProductOptionData);
                }
            };
        }, Promise.resolve());

        // Set localStorage cart data.
        this.lineIdToValidateRebateResponseLine["expiry"] = new Date();
        let expiry_in_hours = 24; //1 days
        let expiry_in_ms = expiry_in_hours * 60 * 60 * 1000;
        localStorage.setItem('cart_validation_data', JSON.stringify({
            value: this.lineIdToValidateRebateResponseLine,
            expiry: (new Date()).getTime() + expiry_in_ms
        }));

        this.salesTaxAdjustment(this.context.cartId);
        setTimeout(() => {
            console.log("Delayed for 3 second.");
            // Hide overlay.
            this.$overlay.hide();
          }, 3000);

        // Pass along to intercepted refreshContent function and force reload if we split lines.
        if (cartActions.length > 0 && cartActions.filter(c => c.action == 'split').length > 0) {
            return this.refreshContent(applyRemoveForRefreshContent, true);
        } else {
            return this.refreshContent(applyRemoveForRefreshContent);
        }
    }

    async getRebateProductOptionData(lines) {

        // Define option names.
        const instantRebateOptionName = 'Apply Instant Rebate';
        const instantRebateOptionYesValue = 'Yes';
        const instantRebateOptionNoValue = 'No';

        // Get product ids from lines.
        const productIdsSet = lines.reduce((acc, line) => {
            if (!line.options.length) {
                return acc;
            }
            const foundOption = line.options.find(option => option.name === instantRebateOptionName);
            if (!foundOption) {
                return acc;
            }
            acc.add(line.productId)
            return acc;
        }, new Set());
        const productIds = Array.from(productIdsSet);

        // console.log({productIds});
        // Return if no product ids found.
        if (productIds.length === 0) {
            return {
                "productIdAnswerObjMapping": {},
                instantRebateOptionName,
                instantRebateOptionYesValue,
                instantRebateOptionNoValue
            };
        }

        // Get product options from lines.
        const productOptions = await this.getProductOptions(productIds);

        // Map product ids to product options.
        const productIdOptionsMapping = productOptions.data.site.products.edges.reduce(
            (obj, edge) => {
                obj[edge.node.entityId] = edge.node.productOptions.edges
                return obj;
            }, {});

        // Create map of product id to rebate answer data from productIdOptionsMapping.
        const productIdRebateAnswerMapping = Object.keys(productIdOptionsMapping).reduce(
            (obj, key) => {
                const foundObj = productIdOptionsMapping[key].find(edge => edge.node.displayName === instantRebateOptionName);
                if (foundObj) {
                    obj[key] = foundObj.node.values.edges;
                }
                return obj;
            }, {});

        // Create product id to answer object mapping from productIdRebateAnswerMapping.
        const productIdAnswerObjMapping = Object.keys(productIdRebateAnswerMapping).reduce(
            (obj, productId) => {
                const foundYesObj = productIdRebateAnswerMapping[productId].find(answer => answer.node.label === instantRebateOptionYesValue);
                const foundNoObj = productIdRebateAnswerMapping[productId].find(answer => answer.node.label === instantRebateOptionNoValue);
                obj[productId] = { [instantRebateOptionYesValue]: foundYesObj.node.entityId, [instantRebateOptionNoValue]: foundNoObj.node.entityId };
                return obj;
            }, {});

        // Return final productIdAnswerObjMapping and static variables.
        return {
            productIdAnswerObjMapping,
            instantRebateOptionName,
            instantRebateOptionYesValue,
            instantRebateOptionNoValue
        };
    }

    async getCart() {
        const cartFetchOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' } };
        return fetch('/api/storefront/carts?include=lineItems.physicalItems.options', cartFetchOptions)
            .then(response => response.json())
            .then(response => response[0])
            .catch(err => {
                console.error(err);
                return swal({
                    text: err,
                    type: 'error',
                }).then(result => console.log('sweet alert:', result))
                    .catch(err => console.error('sweet alert:', err));
            });
    }

    async validateRebates(cart) {

        if(!cart || !cart.id ) {
            confirm('Something went wrong, redirecting you.');
            window.location.href = '/cart-rebate-preview/';
        }
        
        let that = this;
        const $messageBox = $('.alertMessageBox');

        const customerId = $('[data-customer-id]').data('customer-id');

        const validateRebateFetchOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cart_id: cart.id,
                store_hash: window.STORE_HASH,
                customer_id: customerId
            })
        };

        return fetch(window.VALIDATE_REBATE_API_ENDPOINT, validateRebateFetchOptions)
            .then(response => response.json())
            .then(response => {
                // console.log(response)
                if (response.status !== "Success") {
                    that.disableCheckout();
                    if(response.message){
                        $('p.alertBox-message', $messageBox).text(response.message);
                    } else {
                        $('p.alertBox-message', $messageBox).text("Unable to validate rebates");
                    }
                    $messageBox.removeAttr('hidden');
                    this.$overlay.hide();
                }
                return response.data.validate_rebate_response_lines
            })
            .catch(err => {
                console.error(err);
                return swal({
                    text: err,
                    type: 'error',
                }).then(result => console.log('sweet alert:', result))
                    .catch(err => console.error('sweet alert:', err));
            });
    };

    async getProductOptions(productIds) {

        var productOptionsSelectionsFetchOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + window.storefront_api_token
            },
            body: JSON.stringify({
                query:
                    `query ProductOptionsSelections {
                    site {
                        products(entityIds: [${productIds}]) {
                        edges {
                            node {
                            entityId
                            productOptions {
                                edges {
                                node {
                                    entityId 
                                    displayName
                                    ... on MultipleChoiceOption {
                                    values {
                                        edges {
                                        node {
                                            entityId
                                            label
                                            isDefault
                                        }
                                        }
                                    }
                                    }
                                }
                                }
                            }
                            }
                        }
                        }
                    }
                    }`
            })
        };

        return fetch('/graphql', productOptionsSelectionsFetchOptions)
            .then(response => response.json())
            .then(response => response)
            .catch(err => {
                console.error(err);
                return swal({
                    text: err,
                    type: 'error',
                }).then(result => console.log('sweet alert:', result))
                    .catch(err => console.error('sweet alert:', err));
            });
    }

    async getProductVariantIds(productIds) {

        var productVariantsFetchOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + window.storefront_api_token
            },
            body: JSON.stringify({
                query:
                    `query ProductVariants {
                        site {
                        products(entityIds: [${productIds}]) {
                            edges {
                                node {
                                entityId,
                                variants(first: 20) {
                                    edges {
                                    node {
                                        entityId,
                                        sku
                                    }
                                    }
                                }
                                }
                            }
                        }
                        }
                    }`
            })
        };
    
        return fetch('/graphql', productVariantsFetchOptions)
            .then(response => response.json())
            .then(response => response)
            .catch(err => {
                console.error(err);
                return swal({
                    text: err,
                    type: 'error',
                }).then(result => console.log('sweet alert:', result))
                    .catch(err => console.error('sweet alert:', err));
            });
    }

    // Return: DR SKU to Non-DR SKU Variant Id Map
    //
    // {
    //  "Z-GA02081-US-DR": 90
    // }
    // OR
    // null
    async getDRSkuToNonDRSkuIdMapping(lines) {

        let productIdToDRSkusMapping = lines.reduce((acc, line) => {
            let skuSplitArr = line.sku.split('-');
            if(skuSplitArr[skuSplitArr.length - 1] !== 'DR') {
                return acc;
            }
            if(!Array.isArray(acc[line.productId])) {
                acc[line.productId] = [];
            }
            acc[line.productId].push(line.sku);
            return acc;
        }, {});

        const productIds = Object.keys(productIdToDRSkusMapping);

        // Return null if no product ids found.
        if (productIds.length === 0) {
            return null;
        }

        // Get product options values
        const productVariantsGQLRes = await this.getProductVariantIds(productIds);

        const productIdToVariantsGQLResMapping = productVariantsGQLRes.data.site.products.edges.reduce((acc, obj) => {
            acc[obj.node.entityId] = obj.node.variants.edges;
            return acc;
        }, {});

        // Get equivalent Non-DR variant id using the DR sku
        const drSku_NonDRSkuIdMapping = Object.keys(productIdToDRSkusMapping).reduce((acc, id)=> {
            let drSkusArr = productIdToDRSkusMapping[id];

            drSkusArr.forEach(drSku => {
                let skuSplitArr = drSku.split('-');
                skuSplitArr.pop(); // Remove DR from SKU
                const nonDRSku = skuSplitArr.join('-');

                acc[drSku] = productIdToVariantsGQLResMapping[id].find(ele => ele.node.sku === nonDRSku).node.entityId;
            });
            
            return acc;
        }, {});

        return drSku_NonDRSkuIdMapping;
    }

    salesTaxAdjustment(cartId) {
        let customerId = $('body').data('customer-id');
        $.ajax({
            method: 'POST',
            json: true,
            "content-type": "application/json",
            "url": window.SALES_TAX_ADJUSTMENT_API_ENDPOINT,
            timeout: 20000,
            success: function (response) {
                if (response["reload"]) {
                    window.location.reload();
                }
                console.log('success tax adjustment', response);
            },
            error: function (error) {
                console.error('fail tax adjustment', error);
            },
            processData: false,
            data: JSON.stringify({
                cart_id: cartId,
                store_hash: window.STORE_HASH,
                customer_id: customerId
            }),
            dataType: "json"
        });

    }

    addRebateMessaging() {
        var $cartList = $('tbody.cart-list');
        var $cartItems = $cartList.find('tr.cart-item');

        $cartItems.each((i, e) => {
            var $totalRebateMessageElement = $(e).find('.total-with-rebates-message');
            var $applyRebateMessageElement = $(e).find('.apply-rebate-message');

            var itemId = $(e).data('item-id');
            var itemTotalPrice = $(e).data('item-total-price');

            var byot = $(e).data('byot');
            var applyInstantRebate = $(e).data('apply-instant-rebate');
            var isBYOT_Product = $(e).data('byot-product');

            var rebateNotAppliedMessage = null;
            // if(isBYOT_Product && byot !== "Bring Your Own Thermostat" && applyInstantRebate === "Yes") {
            //     rebateNotAppliedMessage = `<a target="_blank" rel="noopener" href="/srp-bring-your-own-thermostat-program-byot/">SRP Bring Your Own Thermostat Program™ (BYOT) rebate</a> was not applied`;
            // } else if (isBYOT_Product && applyInstantRebate === "No")  {
            //     rebateNotAppliedMessage = `The <a target="_blank" rel="noopener" href="/srp-bring-your-own-thermostat-program-byot/">SRP Bring Your Own Thermostat Program™ (BYOT) rebate and SRP rebate</a> were not applied`;
            // } else if (!isBYOT_Product && applyInstantRebate === "No") {
            //     rebateNotAppliedMessage = `The <a target="_blank" rel="noopener" href="/srp-bring-your-own-thermostat-program-byot/">SRP rebate</a> was not applied`;
            // }
            if (applyInstantRebate === "No") {
                rebateNotAppliedMessage = `SRP rebate not applied`;
            }

            var otherRebateMsg = '';
            if(this.lineIdToValidateRebateResponseLine[itemId] && this.lineIdToValidateRebateResponseLine[itemId].message !== null) {
                otherRebateMsg = `<p class="message warn">
                                    <svg class="icon message-block-icon" aria-hidden="true">
                                        <use xlink:href="#icon-warning"></use>
                                    </svg>
                                    ${this.lineIdToValidateRebateResponseLine[itemId].message}
                                </p>`;
            }

            var isTotalEqMSRP = $(e).data("total-equals-msrp");
            if(isTotalEqMSRP) {
                $(e).find('.strike-through-price').removeClass('strike-through-price');
            }

            if (byot === "Bring Your Own Thermostat" && applyInstantRebate === "Yes") {
                $totalRebateMessageElement.html(
                    `<div class="rebate-area byot">
                        <svg aria-hidden="true">
                            <use xlink:href="#icon-byot"></use>
                        </svg>
                        <div class="message-block">
                            <p>
                                Your Price with the <a target="_blank" rel="noopener" href="/srp-bring-your-own-thermostat-program-byot/" data-open-byot-modal="">SRP Bring Your Own Thermostat Program™ (BYOT) rebate</a>
                            </p>
                            <p class="desktop-price">${itemTotalPrice}</p>
                        </div>
                    </div>
                    <div class="rebate-price-wrapper byot">
                        <p class="label">Total with Rebates Applied</p>
                        <p class="price">${itemTotalPrice}</p>
                    </div>`);
                $applyRebateMessageElement.html(
                    `<div class="message-wrapper yes-byot-rebate">
                        <div class="message-block">
                            <p class="message">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-check"></use>
                                </svg>
                                SRP Bring Your Own Thermostat Program™ (BYOT) rebate applied
                            </p>
                        </div>
                    </div>
                    <div class="rebate-price-wrapper mobile-cart-flex yes-byot-rebate">
                        <p class="mobile-rebate-label">Total with Rebates Applied</p>
                        <p class="price">${itemTotalPrice}</p>
                    </div>`);
            } else if (applyInstantRebate === "Yes") {
                $totalRebateMessageElement.html(
                    `<div class="rebate-area only-rebate">
                        <svg aria-hidden="true">
                            <use xlink:href="#icon-byot"></use>
                        </svg>
                        <div class="message-block">
                            <p>
                                Your Price with the SRP rebate
                            </p>
                            <p class="desktop-price">${itemTotalPrice}</p>
                        </div>
                    </div>
                    <div class="rebate-price-wrapper only-rebate">
                        <p class="price">${itemTotalPrice}</p>
                    </div>`);
                var rebateWarnMsgs = '';
                if(rebateNotAppliedMessage !== null) {
                    rebateWarnMsgs = `<p class="warn">
                                        <svg class="icon message-block-icon" aria-hidden="true">
                                            <use xlink:href="#icon-warning"></use>
                                        </svg>
                                        ${rebateNotAppliedMessage}
                                    </p>
                                    ${otherRebateMsg}`;
                }
                $applyRebateMessageElement.html(
                    `<div class="message-wrapper yes-rebate">
                        <div class="message-block">
                            <p class="message">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-check"></use>
                                </svg>
                                SRP rebate applied
                            </p>
                            ${rebateWarnMsgs}
                        </div>
                    </div>
                    <div class="rebate-price-wrapper mobile-cart-flex yes-rebate">
                        <p class="mobile-rebate-label">Total with Rebates Applied</p>
                        <p class="price">${itemTotalPrice}</p>
                    </div>`);
            } else {
                $totalRebateMessageElement.html(
                    `<div class="rebate-area regular-price">
                        <p class="desktop-price">${itemTotalPrice}</p>
                    </div>`);
                
                let dataProductId= $(e).data('product-id');
                if(dataProductId === this.context.themeSettings.sales_tax_adjustment_product_id) {
                    $applyRebateMessageElement.html(
                        `<div class="rebate-price-wrapper mobile-cart-flex no-rebate">
                            <p class="mobile-rebate-label">Total</p>
                            <p class="price">${itemTotalPrice}</p>
                        </div>`);
                }
                if (applyInstantRebate === "No") {
                    $applyRebateMessageElement.html(
                        `<div class="message-wrapper no-rebate">
                            <div class="message-block">
                                <p>
                                    <svg class="icon message-block-icon" aria-hidden="true">
                                        <use xlink:href="#icon-warning"></use>
                                    </svg>
                                    ${rebateNotAppliedMessage}
                                </p>
                                ${otherRebateMsg}
                            </div>
                        </div>
                        <div class="rebate-price-wrapper mobile-cart-flex no-rebate">
                            <p class="mobile-rebate-label">Total with Rebates Applied</p>
                            <p class="price">${itemTotalPrice}</p>
                        </div>`);
                }
            }
        });
    }
}
