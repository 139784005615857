import $ from 'jquery';

export default (customer_info, callback) => {
    //console.log(customer_info);
    $.ajax({
        "url": [window.CREATE_CUSTOMER_API_ENDPOINT].join('/'),
        "method": "POST",
        "timeout": 20000,
        "headers": {
            "Content-Type": "application/json"
        },
        "data": JSON.stringify(customer_info),
        dataType: "json",
        processData: false,
    }).done(function (create_customer_response) {
        callback(create_customer_response);
    }).fail(function (response) {

        if (response.responseJSON.message) {
            callback({
                success: false,
                error_message: 'Error creating account: ' + response.responseJSON.message,
            });
        } else {
            callback({
                success: false,
                error_message: 'Error creating account, please review your information and try again.',
            });
        }
    });
}
