import FormModal from './FormModal';
import validateCustomerEligibility from '../api/validateCustomerEligibility';

export default class ValidateCustomerModal extends FormModal {
  constructor(target, content) {
    super(target, content);
    this.callbacks.willOpen = () => {
      $(this.target).find('[data-modal-errors]').html('').css({ opacity: 0 });
    }
  }
  validateForm(form_element, callback) {
    // Get account and address validity from eligibility form
    let customer_eligibility_form_elements = $(form_element).serializeArray();
    let valid_account_input;
    let valid_account_zip_input;
    let valid_building_type;
    let valid_business_type;
   
    let account_input = customer_eligibility_form_elements.filter(e => e.name === 'account-number')[0].value.trim();
    let zip_input = customer_eligibility_form_elements.filter(e => e.name === 'account-zip')[0].value.trim();
    let building_type = $('#building-type').val().trim();
    let business_type = $('#business-type').val().trim();
 
    valid_account_input = account_input.length > 5;
    valid_account_zip_input = typeof parseInt(zip_input) === 'number' ? true : false;
    valid_building_type = building_type !== "" ? true : false;
    valid_business_type = business_type !== "" ? true : false;
   
    if (!valid_account_input || !valid_account_zip_input || !valid_building_type || !valid_business_type) {
      $(form_element)[0].reportValidity();
      return callback({
        valid: false,
        error_message: 'Please make sure you entered correct business account and business zip code.',
      });
    }

    // Construct eligiblity query using add to cart form elements 
    let customer_eligibility_query = {};
    if (valid_account_input && valid_account_zip_input && valid_building_type) {
      customer_eligibility_query.validate_customer_account_number = customer_eligibility_form_elements.filter(e => e.name === 'account-number')[0].value;
      customer_eligibility_query.validate_customer_zip_code = customer_eligibility_form_elements.filter(e => e.name === 'account-zip')[0].value;
    }
    let validationRequest = {};
    validationRequest.data = customer_eligibility_query;
    validationRequest.store_hash = window.STORE_HASH;
    validationRequest.metadata = {service_region: window.STORE_HASH }
    //console.log('Validation request: ', validationRequest);
    
    let building_type_question = $("[for='building-type']").text();
    let business_type_question = $("[for='business-type']").text();
    let modal_answers = {};
    modal_answers[building_type_question] = building_type;
    modal_answers[business_type_question] = business_type;
    console.log('modal_answers: ', modal_answers);
    window.localStorage.setItem('modal_answers', JSON.stringify(modal_answers));
   
    let wrapped_callback = (response) => {

      if (!response.valid) {
        return callback({
          valid: false,
          error_message: response.error_message,
        });
      } 
      callback(response);
    }

    $('.account-number-validation-help').removeClass('visible');
    $('.address-validation-help').removeClass('visible');
    $('.address-validation-help').siblings('input[type=text]').removeClass('highlighted');
    
    return validateCustomerEligibility(validationRequest, wrapped_callback);
  }
}
