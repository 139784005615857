import InfoModal from './InfoModal';

export default class CartFullModal extends InfoModal {
  constructor(target, content) {
    super(target, content);
    this.Modal.$modal.foundation({
      reveal: {
        bg_class: 'modal-background',
        dismiss_modal_class: 'modal-close',
        close_on_background_click: false,
        close_on_esc: false,
      }
    });
  }

  handleAction(target) {
    switch ($(target).data('action')) {
      case 'deleteCart':
        window.modals.currently_open_modal.Modal.$overlay.show();

        let url = '/api/storefront/carts/' + window.cart_id
        $.ajax({
          type: "DELETE",
          dataType: "json",
          url: url,
          timeout: 20000,
          success: function (response) {
              console.log('DELET Cart response: ', response);
              window.location.href = "/landing/";
          },
          error: function (e) {
              console.error('DELET Cart error: ', e);
          }
        });
        break;
      case 'redirectCheckout':
        window.modals.currently_open_modal.Modal.$overlay.show();
        if(window.location.pathname === '/cart.php') {
          window.location.href = '/checkout';
        } else {
          window.location.href = "/cart.php?redirect";
        }
        break;
    }
  }
}
