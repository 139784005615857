import ApplicationModal from './ApplicationModal';

export default class InfoModal extends ApplicationModal {
    constructor(target, content) {
        super(target, content);
    }
    handleAction(target) {
        switch ($(target).data('action')) {
            case 'next':
                window.modals.nextModal();
                break;
            case 'previous':
                window.modals.previousModal();
                break;
            case 'view-cart':
                window.location.href = "/cart.php";
            case 'dismiss':
                this.close();
                break;
            case 'shop':
                window.location.href = '/shop';
                break;
            default:
                console.error('no action implemented for: ', target);
                break;
        }
    }
    showErrorMessage(error_message) {
        if (error_message === undefined) {
            error_message = 'Something went wrong submitting this information.'
        }
        error_message = `<div class="message">${error_message}</div><div>${this.standard_error_message_end}</div>`;
        $(this.target).find('[data-modal-errors]').addClass('visible').html('<div class="error">' + error_message + '</div>');
    }
}
