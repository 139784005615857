
import ValidateCustomerModal from './modals/ValidateCustomerModal';
import RegisterCustomerModal from './modals/RegisterCustomerModal';
import RegisterCustomerSuccessModal from './modals/RegisterCustomerSuccessModal';

export default class Modals {

    constructor() {
        this.currently_open_modal = null;
        this.current_path = null;
        this.global_callbacks = {
            didClose: () => { }
        };
        // Declare modals
        this.modals = {
            
            CustomerValidation: {
                ValidateCustomerModal: (new ValidateCustomerModal('#validate-customer-modal-target-step2', '#validate-customer-modal-content-step2')),
                RegisterCustomerModal: (new RegisterCustomerModal('#register-customer-modal-target', '#register-customer-modal-content')),
                RegisterCustomerSuccessModal: (new RegisterCustomerSuccessModal('#register-customer-success-modal-target', '#register-customer-success-modal-content')),
            }
        };
        // Declare paths
        this.paths = {
           
            CustomerValidation: {
                unauthenticated_user: ['ValidateCustomerModal', 'RegisterCustomerModal', 'RegisterCustomerSuccessModal'].map(c => this.modals.CustomerValidation[c]),
                validated_unauthenticated_user: ['RegisterCustomerModal', 'RegisterCustomerSuccessModal'].map(c => this.modals.CustomerValidation[c]),
            },
        };
        this.bindModals();
        return this;
    }

    bindModals() {
        $(document).on('click', '[data-add-to-cart]', (e) => {
            try {
                if (window.path_complete === undefined) {
                    let form_to_validate;
                    let is_sticky = $(e.target).parents('#sticky_addtocart').length > 0;
                   
                    if (is_sticky) {
                        form_to_validate = $('[data-cart-item-add-2]')[0];
                    } else {
                        form_to_validate = $('[data-cart-item-add]')[0]
                    }
                    let is_logged_in = parseInt($('[data-customer-id]').data('customerId')) > 0;
                    // Unauthenticated user or peak time product selection
                    if (!is_logged_in) {
                        if (!$(form_to_validate)[0].checkValidity()) {
                            return $(form_to_validate)[0].reportValidity();
                        }
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        let target = e.target;
                        window.modals.onPathComplete = () => {
                            window.path_complete = true;
                            $(target).click();
                            window.modals.onPathComplete = undefined;
                          window.path_complete = undefined;
                        };
                        window.modals.initializing_action = 'add-to-cart';
                        if (is_sticky) {
                            window.modals.initializing_action = 'sticky-add-to-cart';
                        }
                        if (!is_logged_in) {
                            if (sessionStorage && sessionStorage.getItem('validated_customer')) {
                                try {
                                    let customer_data = JSON.parse(sessionStorage.getItem('validated_customer'));
                                    window.modals.modals.CustomerValidation.RegisterCustomerModal.updateCustomerInfo(customer_data);
                                    window.modals.openPath(window.modals.paths.CustomerValidation.validated_unauthenticated_user);
                                } catch(e){
                                    console.error('Error parsing customer data');
                                    window.modals.openPath(window.modals.paths.CustomerValidation.unauthenticated_user);
                                }
                            } else {
                                window.modals.openPath(window.modals.paths.CustomerValidation.unauthenticated_user);
                            }
                        } else {
                            window.modals.openPath(window.modals.paths.PeakTimeSavingsEnrollment.authenticated_user);
                        }
                    }
                }
            } catch (e) {
                window.modals.openPath(window.modals.paths.CustomerValidation.unauthenticated_user);
            }
        });
        $(document).on('click', '[data-create-customer]', (e) => {
            e.preventDefault();
            window.modals.initializing_action = 'create-customer';
            window.modals.openPath(window.modals.paths.CustomerValidation.unauthenticated_user);
        });
        $(document).on('click', '[data-open-login]', (e) => {
            e.preventDefault();
            window.modals.currently_open_modal ? window.modals.currently_open_modal.close() : null;
            $('body').addClass('themevale_open-Account');
            $('[name=login_email]:visible').focus();
        });
        $(document).on('input', '[data-has-complexity-requirements]', (e) => {
            let password = e.target.value;
            let target = $(e.target).siblings('[data-complexity-requirements]');
            $(target).addClass('visible');
            let valid = true;
            let error_message;
            if (password.length < 8) {
                $(target).find('[data-length-complexity-requirement]').css({ color: 'red' });
                error_message = 'Password must be longer than 8 characters.';
                valid = false;
            } else {
                $(target).find('[data-length-complexity-requirement]').css({ color: 'green' });
            }
            if (password.match(/\w/) && password.match(/\d/) && password.match(/[^a-zA-Z0-9 ]+/)) {
                $(target).find('[data-character-complexity-requirement]').css({ color: 'green' });
            } else {
                $(target).find('[data-character-complexity-requirement]').css({ color: 'red' });
                error_message = 'Password must contain a letter, number, and special character (e.g. !@#$%).';
                valid = false;
            }
            if (valid) {
                $(target).removeClass('visible');
            }
        });
    }

    previousModal() {
        let prev_modal = this.current_path[this.current_path.indexOf(this.currently_open_modal)];
        if (prev_modal) {
            prev_modal.prev();
        }
    }

    nextModal() {
        if (!window.next_modal_disabled) {
            if (this.currently_open_modal.is_form_modal && !this.currently_open_modal.checkValidity()) {
                this.currently_open_modal.reportValidity();
            }
            let next_modal = this.current_path[this.current_path.indexOf(this.currently_open_modal) + 1];
            if (next_modal) {
                this.currently_open_modal.next();
            } else {
                this.currently_open_modal.completePath();
            }
        }
        window.setTimeout(function () {
            window.next_modal_disabled = false;
        }, 3000);
        window.next_modal_disabled = true;
    }

    selectPath(path) {
        this.current_path = path
    }

    openPath(path, index = 0) {
        this.selectPath(path);
        this.current_path[index].open();
    }
};
