import RedirectCustomerModal from './modals/RedirectCustomerModal';
import RegisterCustomerModal from './modals/RegisterCustomerModal';
import RegisterCustomerSuccessModal from './modals/RegisterCustomerSuccessModal';
import { getLocalStorageWithExpiry } from './localStorage-with-expiry';
import { addToCart, addProductsToCart } from './api/addToCart';
import RedirectCartModal from './modals/RedirectCartModal';
import CartFullModal from './modals/CartFullModal';
import MessageModal from './modals/MessageModal';
import LoadingModal from './modals/LoadingModal';
import HeatWaterQuestionErrorModal from './modals/HeatWaterQuestionErrorModal';
import BRCInfoModal from './modals/BRCInfoModal';
import HeatQuestionInfoModal from './modals/HeatQuestionInfoModal';
import ShowerheadQuantityInfoModal from './modals/ShowerheadQuantityInfoModal';

export default class Modals {

    constructor(context) {
        this.context = context;
        this.currently_open_modal = null;
        this.current_path = null;
        this.global_callbacks = {
            didClose: () => { }
        };
        // Declare modals
        this.modals = {
            CustomerValidation: {
                RedirectCartModal: (new RedirectCartModal('#redirect-cart-modal-target', '#redirect-cart-modal-content')),
                RedirectCustomerModal: (new RedirectCustomerModal('#redirect-customer-modal-target', '#redirect-customer-modal-content')),
                RegisterCustomerModal: (new RegisterCustomerModal('#register-customer-modal-target', '#register-customer-modal-content')),
                RegisterCustomerSuccessModal: (new RegisterCustomerSuccessModal('#register-customer-success-modal-target', '#register-customer-success-modal-content'))
            },
            PurlValidation: {
                MessageModal: (new MessageModal('#message-modal-target', '#message-modal-content'))
            },
            ValidationQuestions: {
                HeatWaterQuestionErrorModal: (new HeatWaterQuestionErrorModal('#heat-water-question-error-modal-target', '#heat-water-question-error-modal-content'))
            },
            BRCInfoModal: {
                BRCInfoModal: (new BRCInfoModal('#brc-info-modal-target', '#brc-info-modal-content'))
            },
            HeatQuestionInfoModal: {
                HeatQuestionInfoModal: (new HeatQuestionInfoModal('#heat-question-info-modal-target', '#heat-question-info-modal-content'))
            },
            ShowerheadQuantityInfoModal: {
                ShowerheadQuantityInfoModal: (new ShowerheadQuantityInfoModal('#showerhead-quantity-info-modal-target', '#showerhead-quantity-info-modal-content'))
            },
            Loading: (new LoadingModal('#loading-modal-target', '#loading-modal-content')),
            CartValidation: {
                CartFullModal: (new CartFullModal('#cart-full-modal-target', '#cart-full-modal-content'))
            },
        };
        // Declare paths
        this.paths = {
            CustomerValidation: {
                unauthenticated_user: ['RedirectCustomerModal'].map(c => this.modals.CustomerValidation[c]),
                authenticated_user: ['RegisterCustomerModal', 'RegisterCustomerSuccessModal'].map(c => this.modals.CustomerValidation[c]),
            },
            ValidationQuestions: {
                heatWaterQuestionError: ['HeatWaterQuestionErrorModal'].map(c => this.modals.ValidationQuestions[c]),
            },
            BRCInfoModal: {
                infoModal: ['BRCInfoModal'].map(c => this.modals.BRCInfoModal[c]),
            },
            HeatQuestionInfoModal: {
                infoModal: ['HeatQuestionInfoModal'].map(c => this.modals.HeatQuestionInfoModal[c]),
            },
            ShowerheadQuantityInfoModal: {
                infoModal: ['ShowerheadQuantityInfoModal'].map(c => this.modals.ShowerheadQuantityInfoModal[c]),
            },
        };

        this.redirectCheckModal();
        this.cartFullModal();
        this.bindModals();
        return this;
    }

    loadCustomerData() {
        if (getLocalStorageWithExpiry('registration_token') != undefined) {
            window.modals.modals.CustomerValidation.RegisterCustomerModal.updateCustomerInfo();
        }
    }

    redirectCheckModal() {
        window.setTimeout(function () {
            let disabled_pathnames = [
                '/',
                '/login.php',
                '/purl/',
                '/purl-test/',
                '/landing/',
                '/program-info/',
                '/about/',
                '/shipping-returns/',
                '/faqs/',
                '/contact-us/',
                '/terms-and-conditions/',
                '/terms-conditions/',
                '/terms-of-service/',
                '/privacy-notice/',
                '/privacy-policy/',
                '/efficiency-101/',
                '/efficiency-101/why-watersense-products-make-sense-/',
                '/efficiency-101/how-do-you-know-when-its-time-to-replace-your-showerhead/',
                '/efficiency-101/faucet-aerator-101/',
                '/efficiency-101/do-lowflow-showerheads-mean-an-end-to-luxurious-showers/'
            ];

            let is_logged_in = parseInt($('[data-customer-id]').data('customerId')) > 0;
            let is_authenticated = getLocalStorageWithExpiry('registration_token') != null;

            if (!is_logged_in && !is_authenticated && disabled_pathnames.indexOf(window.location.pathname) < 0) {
                window.modals.openPath(window.modals.paths.CustomerValidation.unauthenticated_user);
                window.location.replace(window.location.origin);
            }
        }, 300);
    }

    cartFullModal() {
        window.setTimeout(function () {
            let disabled_pathnames = [
                '/',
                '/account.php',
                '/login.php',
                '/purl/',
                '/purl-test/',
                '/landing/',
                '/program-info/',
                '/about/',
                '/shipping-returns/',
                '/faqs/',
                '/contact-us/',
                '/terms-and-conditions/',
                '/terms-conditions/',
                '/terms-of-service/',
                '/privacy-notice/',
                '/privacy-policy/',
                '/efficiency-101/',
                '/efficiency-101/why-watersense-products-make-sense-/',
                '/efficiency-101/how-do-you-know-when-its-time-to-replace-your-showerhead/',
                '/efficiency-101/faucet-aerator-101/',
                '/efficiency-101/do-lowflow-showerheads-mean-an-end-to-luxurious-showers/'
            ];

            if (window.cart_id !== ''
                && !window.location.search.includes('redirect')
                && disabled_pathnames.indexOf(window.location.pathname) < 0) 
            {
                if (window.location.pathname === '/cart.php') {
                    $('.modal-header').show();
                    $('.modal-description-content').hide();
                }
                window.modals.modals.CartValidation.CartFullModal.open();
            }
        }, 300);
    }

    bindModals() {
        $(document).on('click', '[data-register-customer]', (e) => {
            e.preventDefault();
            if (getLocalStorageWithExpiry('registration_token') != undefined) {
                window.modals.initializing_action = 'register-customer';
                window.modals.openPath(window.modals.paths.CustomerValidation.authenticated_user);
            } else {
                if ($('#account-eligibility-form #account-number').length) {
                    $('.themevale_background').click();
                    $('#account-eligibility-form #account-number').scroll();
                    $('#account-eligibility-form #account-number').focus();
                }
            }
        });

        $(document).on('click', '[data-add-to-cart]', (e) => {
            let is_logged_in = parseInt($('[data-customer-id]').data('customerId')) > 0;
            var form = $(e.target).parents('form');
            // Check that form is valid before we continue. 
            if(form && form[0] && form[0].checkValidity && form[0].checkValidity() == false){
                return;
            }
            if (!is_logged_in && !window.logged_in) {
                e.preventDefault();
                window.targetButton = e.target;
                window.modals.initializing_action = 'add-to-cart';
                window.modals.openPath(window.modals.paths.CustomerValidation.authenticated_user);
                return;
            }

            if ($(e.target).data('autoAddSubmitted') == null) {
                if (window.location.href.match(/full-price-upgraded-single-bath-save-energy-water-kit/)) {
                    e.preventDefault();
                    let auto_add_product_id = this.context.themeSettings.auto_add_showerhead_product_id;
                    let auto_add_quantity = 1;

                    addToCart(auto_add_product_id, auto_add_quantity, null, (auto_add_success) => {
                        if (auto_add_success) {
                            $(e.target).data('autoAddSubmitted', true);
                            $(e.target).trigger('click');
                        } else {
                            window.modals.modals.PurlValidation.MessageModal.open();
                            $('#message-1').show();
                            $('#message-1').text("We're sorry there seems to be a network issue! Please reload and try again!");
                        }
                    });
                } else if (window.location.href.match(/upgraded/)) {
                    if ($(e.target).data('autoAddSubmitted') == null) {
    
                        e.preventDefault();
                        
                        const checkedSKU = $('form input[type="radio"]:checked');
                        const sku = checkedSKU.data('sku');
                        const line_items = window.kitSkuMap[sku];
                        console.log({line_items});
                        
                        addProductsToCart(line_items, (auto_add_success) => {
                            if (auto_add_success) {
                                $(e.target).data('autoAddSubmitted', true);
                                $(e.target).trigger('click');
                            } else {
                                window.modals.modals.PurlValidation.MessageModal.open();
                                $('#message-1').show();
                                $('#message-1').text("We're sorry there seems to be a network issue! Please reload and try again!");
                            }
                        });
                    }
                }
            }
        });

        $(document).on('click', '[data-add-from-landing-page-to-cart]', (e) => {
            $('button.button').prop('disabled', true);
            
            let is_logged_in = parseInt($('[data-customer-id]').data('customerId')) > 0;
            if (!is_logged_in && !window.logged_in) {
                window.targetButton = e.target;
                window.modals.initializing_action = 'add-from-landing-page-to-cart';
                window.modals.openPath(window.modals.paths.CustomerValidation.authenticated_user);
                setTimeout(function() {
                    $('button.button').prop("disabled",false);
                }, 1000);
            } else {

                let line_items = [{
                    productId: parseInt($(e.target).data('productId')),
                    quantity: 1
                }];
                
                // Add showerhead if data-auto-add-showerhead-quantity is on target element
                if ($(e.target).data('autoAddShowerheadQuantity')) {
                    line_items.push({
                        productId: this.context.themeSettings.auto_add_showerhead_product_id,
                        quantity: parseInt($(e.target).data('autoAddShowerheadQuantity'))
                    })
                }
                
                addProductsToCart(line_items, (res) => {
                    if(res) {
                        window.location.href = "/cart.php?redirect";
                    }
                    setTimeout(function() {
                        $('button.button').prop("disabled",false);
                    }, 1000);
                });
            }
        });

        $(document).on('input', '[data-has-complexity-requirements]', (e) => {
            let password = e.target.value;
            let target = $(e.target).siblings('[data-complexity-requirements]');
            $(target).removeClass('hidden');
            $(target).addClass('show');

            let valid = true;

            if (password.length < 8) {
                $(target).find('[data-length-complexity-requirement]').css({ color: 'red' });
                valid = false;
            } else {
                $(target).find('[data-length-complexity-requirement]').css({ color: 'green' });
            }

            // Include at least one number, lower-case letter, upper-case letter and special character
            if (password.match(/\d/) && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[^a-zA-Z0-9]/)) {
                $(target).find('[data-character-complexity-requirement]').css({ color: 'green' });
            } else {
                $(target).find('[data-character-complexity-requirement]').css({ color: 'red' });
                valid = false;
            }

            if (valid) {
                $(target).removeClass('show');
                $(target).addClass('hidden');
            }
        });
    }

    previousModal() {
        let prev_modal = this.current_path[this.current_path.indexOf(this.currently_open_modal)];
        if (prev_modal) {
            prev_modal.prev();
        }
    }

    nextModal() {
        if (!window.next_modal_disabled) {
            if (this.currently_open_modal.is_form_modal && !this.currently_open_modal.checkValidity()) {
                this.currently_open_modal.reportValidity();
            }
            let next_modal = this.current_path[this.current_path.indexOf(this.currently_open_modal) + 1];
            if (next_modal) {
                this.currently_open_modal.next();
            } else {
                this.currently_open_modal.completePath();
            }
        }
        window.setTimeout(function () {
            window.next_modal_disabled = false;
        }, 3000);
        window.next_modal_disabled = true;
    }

    selectPath(path) {
        this.current_path = path
    }

    openPath(path, index = 0) {
        this.selectPath(path);
        this.current_path[index].open();
    }
};
